import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container"

// Components
import HowToUse from "./how-to-use"
import RespiHowToUse from "./respi-how-to-use"

const StyledVideos = styled.section`
  padding: 60px 0;
  text-align: center;

  ${breakpoint.medium`
    padding: 80px 0 60px;
  `}

  ${Container} {
    max-width: 1300px; 
   }
 
  .columns {
    display: flex;
    flex-direction: column;

    ${breakpoint.medium`
    flex-direction: row;
    gap: 20px;
  `}


    & div {
      width: 100% !important;
    }
  
  }
  
  
`

const Videos = props => {
  return (
    <StyledVideos>
      <Container>
        <h2>How does the Visby PCR platform work</h2>
        <p>Less than 15 seconds of hands-on time. Results in under 30 minutes.</p>
        <div className="columns">
          <HowToUse />
          <RespiHowToUse />
        </div>
      </Container>
    </StyledVideos>
  )
}

export default Videos


