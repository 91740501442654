import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

const StyledHowToUse = styled.div`
  position: relative;
  padding: 20px 0;
  border-bottom-width: 0;
  
  ${breakpoint.medium`
    padding: 40px 0;
    `}

  .quick-results__heading {
    max-width: 827px;
    margin: 0 auto 30px;
    text-align: center;

    ${breakpoint.medium`
      margin-bottom: 60px;
    `}  
  }

  h4 {
    margin-top: 10px!important;
  }

`

const StyledVideo = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 56%;

  .video__placeholder {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: ${props => (props.showPlaceholder ? "flex" : "none")};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 500;

    &:hover {
        border: 1px solid white;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 499;
  }
`

const Video = props => {
  return (
    <StyledVideo
      onClick={props.onClick}
      showPlaceholder={props.showPlaceholder}
      onKeyDown={props.onClick}
      tabIndex="0"
      role="none"
    >
        <StaticImage
          src="../../../assets/images/sexual-health-test/video-placeholder.jpg"
          width={1260}
          alt="How to use the Visby Medical test"
          className="video__placeholder"
          style={{
            position: "absolute",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      <iframe
        title="How to use the Visby Medical test"
        src={
          props.showPlaceholder
            ? "https://player.vimeo.com/video/799359707?h=d12f521226"
            : "https://player.vimeo.com/video/799359707?h=d12f521226&autoplay=1"
        }
        allow="autoplay; fullscreen"
      ></iframe>
    </StyledVideo>
  )
}

class HowToUse extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPlaceholder: true,
    }
  }

  hidePlaceholder = () => {
    if (this.state.showPlaceholder) {
      this.setState({
        showPlaceholder: false,
      })
    }
  }

  render = () => (
    <StyledHowToUse>
          <Video
          showPlaceholder={this.state.showPlaceholder}
          onClick={this.hidePlaceholder}
          />
          <h4>Visby Medical Sexual Health Test</h4>
    </StyledHowToUse>
  )
}

export default HowToUse
